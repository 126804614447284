import React from "react";
import {API_URL, baseEventUrl} from "../config";

const dataStructuresUrlV2 = (event_id, id) => {
    return `${baseEventUrl(event_id)}data-structures${id ? ('/'+id) : ''}`
}

const DataStructuresService = {
    dataStructuresUrlV2,
};

export default DataStructuresService;