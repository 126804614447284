import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {Insights, TableChart} from "@mui/icons-material";

export default function CustomToggleGraph({graphMode, handleGraphMode}) {
    return (
        <ToggleButtonGroup
            value={graphMode}
            exclusive
            size={'small'}
            onChange={handleGraphMode}
            aria-label="text alignment"
        >
            <ToggleButton value={false} aria-label="tableMode" sx={{borderRadius: '1.1rem'}}>
                <TableChart color={'primary'} sx={{marginRight: 1}} fontSize={'small'}/>Tabella
            </ToggleButton>
            <ToggleButton value={true} aria-label="graphMode" sx={{borderRadius: '1.1rem'}}>
                <Insights color={'primary'} sx={{marginRight: 1}} fontSize={'small'}/>Grafico
            </ToggleButton>
        </ToggleButtonGroup>
    );
}