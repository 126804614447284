//export const API_URL = "https://winston-kampaay-api-3aufgvstya-ew.a.run.app/" // PROD
// export const API_URL = 'https://winston-kampaay-dev-api-3aufgvstya-ey.a.run.app/' // DEV europe-west3
export const API_URL = 'https://kampaay-api.meetwinston.co/' // SSO Kampaay DEV europe-west1
//export const API_URL = "http://localhost:8080/";

export const appName = process.env.REACT_APP_APP_NAME || 'Dashboard'

export const baseEventUrl = (event_id) => {
    return `${API_URL}events/${event_id ? (event_id+"/") : ""}`
}
