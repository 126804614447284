import React, {useContext} from "react";
import {APP_BAR_HEIGHT, customTheme} from "../../theme/customTheme";
import {Box, Button} from "@mui/material";
import {Checklist, Dashboard, List, PersonalVideo, Settings, SettingsOutlined} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {GlobalContext} from "../../state/global";
import {setSelectedRoute} from "../../state/global/globalActions";
import {some} from "lodash";
import {useAuthContext} from "../../auth/hooks";

const pages = [
    {
        id: 'home',
        label: 'Overview',
        link: '/',
        icon: <PersonalVideo/>,
        needAdmin: false
    },
    {
        id: 'iscrizioni',
        label: 'Iscrizioni',
        link: '/iscrizioni',
        icon: <Checklist/>,
        subPages: ['/user'],
        needAdmin: false
    },
    {
        id: 'settings',
        label: 'Impostazioni',
        link: '/settings',
        icon: <SettingsOutlined/>,
        needAdmin: true
    }
];

const CustomMenu = () => {
    const {event_id} = useParams()
    const {isAdmin} = useAuthContext()
    const [globalState, dispatch] = useContext(GlobalContext)
    const navigate = useNavigate()

    const currentLocation = (route, subPages) => {
        //console.log("globalState?.selectedRoute, route:",globalState?.selectedRoute, route)
        return globalState?.selectedRoute === `/${event_id}${route}` || (some(subPages, function (o) {
            return globalState?.selectedRoute.includes(o)
        }))
    }

    function handleRoutes(route) {
        dispatch(setSelectedRoute(`/${event_id}${route}`))
        navigate(`/${event_id}${route}`)
    }

    return (
        <Box position={'sticky'}
             top={APP_BAR_HEIGHT}
             display={'flex'}
             justifyContent={'flex-start'}
             alignItems={'center'}
             py={1}
             zIndex={1}
        >
            {
                pages.map((page) => (
                    (page.needAdmin && !isAdmin)
                        ? null
                        : <Button key={page.id}
                                variant={currentLocation(page.link, page.subPages) ? 'menu-selected' : 'menu'}
                                startIcon={page.icon}
                                onClick={() => handleRoutes(page.link)}>
                            {page.label}
                        </Button>
                ))
            }
        </Box>
    )
}

export default CustomMenu