import React, {useContext, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Button, Card, Container, Dialog, DialogContent, Snackbar, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/images/Logo-Kampaay-Orange-2.png'
import logoWinston from '../assets/images/logo_bicolor_1000px/Logo winston - centered - rosso bianco@1K.png'
import {Alert} from "@mui/lab";
import {ArrowForward, QrCodeScanner} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import {auth} from "../firebase/clientApp";
import {GlobalContext} from "../state/global";
import IconButton from "@mui/material/IconButton";
import QRCode from 'react-qr-code'
import CustomTooltip from "../components/CustomTooltip";
import {setAuthState} from "../state/global/globalActions";
import jwt_decode from "jwt-decode";

export function QrCodeAppCheckin({title, size = 'large', color = 'white'}) {
    const [openQr, setOpenQr] = useState(false)

    function handleOpenQr(event) {
        event.preventDefault()
        event.stopPropagation()
        setOpenQr(true)
    }

    function handleCloseQr(event) {
        event.preventDefault()
        event.stopPropagation()
        setOpenQr(false)
    }

    return (<>
            <Stack direction={'row'} alignItems={'center'} onClick={handleOpenQr}>
                <Button onClick={handleOpenQr}
                        size={size}
                        startIcon={<QrCodeScanner fontSize={size} sx={{
                            color: color
                        }}/>}>
                    {title}
                </Button>
            </Stack>
        <Dialog
            open={openQr}
            onClose={handleCloseQr}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{
                backgroundColor: customTheme.palette.accent.main,
            }}>
                <QRCode
                    title="AISmartReport.it"
                    value={`${window.location.origin.toString()}/checkin`}
                    level={'H'}
                    bgColor={customTheme.palette.accent.main}
                    fgColor={'white'}
                    //size={100}
                />
                <Typography fontWeight={'bold'}
                            textAlign={'right'}
                            color={'white'}>
                    Winston App Check-In
                </Typography>
            </DialogContent>
        </Dialog>
    </>);
}

const Login = () => {
    const {event_id} = useParams()
    document.body.style.backgroundColor = customTheme.palette.secondary.main;
    const sx = {
        "& input": {
            textAlign: "center",
            color: "white",
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
        },
    }

    const [globalState, dispatch] = useContext(GlobalContext)

    let navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        auth.signInWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
                const token = await userCredential?.user?.getIdToken()

                if (token) {
                    const role = jwt_decode(token)?.role
                    dispatch(setAuthState({isAdmin: role === 'ADMIN', role: role}))
                }

                navigate(`/${event_id}/`, {replace: true})
                //window.location.reload();
            })
            .catch((error) => {
                //console.log("error:",error)
                const resMessage = (error?.response &&
                        error?.response?.data &&
                        error?.response?.data?.message) ||
                    error?.message ||
                    error.toString();
                setLoading(false);
                setMessage("Ops, login non andato a buon fine...");
            });

        /*authService.login(email, password).then(
            () => {
                navigate("/");
                window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        )*/
    }

    const handleCloseError = () => setMessage("");

    return (
        <main>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}}>
                <form onSubmit={handleLogin}>
                    <Stack mt={2} alignItems={'center'}>
                        <img src={logoWinston} width={'20%'} style={{margin: '0rem'}}/>
                        <img src={logo} width={'40%'} style={{margin: '0rem'}}/>
                    </Stack>
                    <Card style={{height: '100%', textAlign: 'right'}} variant={'login'}>
                        <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                            <Typography variant={'h5'} color={'white'}>Accedi</Typography>
                            <TextField style={{maxWidth: '31.25rem'}} sx={{...sx, my: 2}} size={'small'} fullWidth
                                       placeholder={'Email'} onChange={handleSetEmail}/>
                            <TextField style={{maxWidth: '31.25rem'}} sx={{...sx, mb: 2}} size={'small'} fullWidth
                                       placeholder={'Password'} type={"password"} onChange={handleSetPassword}/>
                            <Button type={'submit'}
                                    variant={"menu-selected"}
                                    style={{width: '35%', fontSize: '1rem', color: 'white', background: customTheme.palette.accent.main}}>
                                Login
                            </Button>
                            <Stack mt={'10%'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                <Button onClick={() => navigate(`/${event_id}/checkin`)}
                                        sx={{color: 'white'}}
                                        variant={'outlined'}
                                        endIcon={<ArrowForward/>}
                                        style={{maxWidth: '31.25rem'}}>
                                    Vai all'app di check-in
                                </Button>
                                <QrCodeAppCheckin/>
                            </Stack>
                        </Stack>
                    </Card>
                    <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                        <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error"
                               sx={{width: '100%'}}>
                            {message}
                        </Alert>
                    </Snackbar>
                </form>
            </Container>
        </main>
    )
}

export default Login
