import React from "react";
import CustomAppBar from "./CustomAppBar";
import CustomMenu from "./CustomMenu";

const Header = ({children}) => {
    return (<>
            <CustomAppBar/>
            {children}
        </>
    )
}

export default Header