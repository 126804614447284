import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {IconButton} from "@mui/material";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";
import {useParams} from "react-router-dom";

export default function CheckinAppBar({handleDialogOpen}) {
    const {event_id} = useParams()
    const [{data: dataSettings, loading, error}] = useAxios(
        SettingsService.settingsUrl(event_id), {method: "GET", useCache: false, manual: false}
    )

    return (
        <Box sx={{ flexGrow: 1, }}>
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color={'primary'}>
                        Check in
                    </Typography>
                    <Typography variant={'body1'} textAlign="center" color={'primary'}>
                        {dataSettings?.settings?.eventName || ''}
                    </Typography>
                    {<IconButton
                        size="large"
                        aria-label="cerca iscritto"
                        color="inherit"
                        onClick={handleDialogOpen}
                    >
                        <PersonSearchIcon />
                    </IconButton>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
