import {DialogTitle, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import React from "react";
import {customTheme} from "../theme/customTheme";

const CustomDialogTitle = ({title, handleCloseDialog}) => {
    return (
        <DialogTitle sx={{
            background: customTheme.palette.accent.main
        }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {title}
                <IconButton onClick={handleCloseDialog} sx={{color:'white'}}><Close/></IconButton>
            </Stack>
        </DialogTitle>
    )
}

export default CustomDialogTitle