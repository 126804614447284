import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAGlrMlLYZG4Ts-ERRUNGvcKecCubD9nv4",
    authDomain: "winston-kampaay.firebaseapp.com",
    projectId: "winston-kampaay",
    storageBucket: "winston-kampaay.appspot.com",
    messagingSenderId: "430992562113",
    appId: "1:430992562113:web:75e30973da87dc26464140"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}