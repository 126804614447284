import React, {useContext, useState} from "react";
import {alpha, Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Add, AddCircle, Edit, EditOutlined, Preview, Star} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import CustomTooltip from "./CustomTooltip";
import SettingsDialog, {SettingsDialogTypes} from "../pages/settings/SettingsDialog";
import {GlobalContext} from "../state/global";
import HandleFormDialog from "../pages/settings/HandleFormDialog";
import {red} from "@mui/material/colors";
import {useParams} from "react-router-dom";

const CustomFormCard = ({id, label, description}) => {
    const {event_id} = useParams()
    const [globalState, ] = useContext(GlobalContext)
    //console.log("globalState:",globalState)
    //console.log("globalState.dataStructures[id]:",globalState?.dataStructures[id])

    const [dialogState, setDialogState] = useState({
        open: false,
        data: null,
        type: null
    })

    const handleOpenDialog = (e) => {
        switch (e.currentTarget.id) {
            case 'edit-form':
                setDialogState({
                    open: true,
                    type: SettingsDialogTypes.DATA_STRUCTURES_INFO,
                    data: globalState.dataStructures[id]
                })
                break
            case 'add-field':
                //console.log("add-field:",id)
                setDialogState({
                    open: true,
                    type: SettingsDialogTypes.DATA_STRUCTURES_FIELDS,
                    data: globalState.dataStructures[id]
                })
        }

    }

    return (
        <Card variant={'outlined'} sx={{
            maxWidth: 345,
            minWidth: 260,
            borderColor: globalState.dataStructures[id].main ? customTheme.palette.accent.main : 'ligthgrey'
            //background: alpha(customTheme.palette.primary.main, 0.1)
        }}>
            <CardHeader
                /*avatar={
                    globalState.dataStructures[id].main ?
                        <CustomTooltip title={'Form principale'} children={
                            <Avatar sx={{ bgcolor: customTheme.palette.accent.main, width: 24, height: 24 }}>
                                <Star fontSize={'small'}/>
                            </Avatar>}/>
                        : null
                }*/
                action={
                    <IconButton id={'edit-form'} color={'accent'} onClick={handleOpenDialog}>
                        <EditOutlined/>
                    </IconButton>
                }
                title={label}
                subheader={`id: ${id}`}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {description} ...
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <CustomTooltip title={'Aggiungi campi personalizzati di segreteria'} children={
                    <Button aria-label="add"
                            id={'add-field'}
                            //variant={'outlined'}
                            color={'accent'}
                            size={'small'}
                            startIcon={<Add />}
                            onClick={handleOpenDialog}
                    >
                        Aggiungi campi
                    </Button>
                }/>
                {globalState.dataStructures[id].main &&
                    <CustomTooltip title={'Anteprima form utente'} children={
                        <Button aria-label="add"
                                //variant={'outlined'}
                                color={'accent'}
                                size={'small'}
                                startIcon={<Preview/>}
                                //onClick={handleOpenDialog}
                                href={`https://kampaay.meetwinston.co/${event_id}/register/${id}`}
                                target={'_blank'}
                        >
                            Anteprima form utente
                        </Button>
                    }/>}
            </CardActions>
            <HandleFormDialog dialogState={dialogState} setDialogState={setDialogState}/>
        </Card>
    )
}

export default CustomFormCard