import React, {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Stack, Typography} from "@mui/material";
import {Check, Delete, DeleteForever, OpenInNew} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import CustomLoader from "./CustomLoader";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import UserService from "../services/user.service";
import {Alert} from "@mui/lab";

const CustomConfirmDialog = ({label, confirmAction, icon, color = 'accent', variant, confirmText = `Sei sicuro?`}) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, confirmAction: null, responseMessage: ''});

    const handleToggleConfirmDialog = (confirmAction, responseMessage) => {
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            responseMessage: responseMessage || null,
            confirmAction: confirmAction || null
        })
    }

    return (
        <>
            <Button startIcon={icon}
                    color={color}
                    variant={variant}
                    onClick={() => handleToggleConfirmDialog(confirmAction)}
            >
                {label}
            </Button>
            <Dialog open={openConfirmDialog.open} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    <DialogContentText>
                        {confirmText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'dialog-button'} onClick={handleToggleConfirmDialog}>{'Annulla'}</Button>
                    <Button variant={'submit-accent'}
                            onClick={openConfirmDialog.confirmAction ? () => openConfirmDialog.confirmAction() : null}
                    >
                        {'CONFERMA'}
                    </Button>
                </DialogActions>
                {!!openConfirmDialog?.responseMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {openConfirmDialog?.responseMessage || ''}
                    </Alert>}
            </Dialog>
        </>
    );
}

export const CustomFileUploader = ({_handleChange, _file, field, fileLabel, uploaded, refetch}) => {
    const {event_id, id} = useParams()
    const [file, setFile] = useState(null)

    const [{data, loading, error}, uploadFiles] = useAxios(
        {}, {manual: true}
    )

    const handleChange = (file) => {
        setFile(file);
    };

    const sendFile = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        const formData = new FormData();
        formData.append('user_id', id);
        if (file !== null) {
            formData.append('file', file)
        }

        formData.append('field', field)

        uploadFiles({
            url: `${UserService.userDataUrl(event_id, id)}/upload`,
            method: "PUT",
            data: formData
        })
            .then((res) => {
                console.log("caricato!")
                refetch()
            })
            .catch((err) => console.log("err:", err))
    }

    const deleteFile = async () => {
        uploadFiles({
            url: `${UserService.userDataUrl(event_id, id)}/upload`,
            method: "DELETE",
            data: {
                field: field
            }
        })
            .then((res) => {
                console.log("eliminato!")
                refetch()
            })
            .catch((err) => console.log("err:", err))
    }

    return (
        <Box sx={{width: {xs: '100%', md: 'min(25rem, 100%)'}, py: 2}}>
            {uploaded ?
                <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1}>
                    {/*<Check sx={{color: customTheme.palette.accent.main, fontSize: 32}}/>*/}
                    <Button startIcon={<OpenInNew/>}
                            color={"accent"}
                            variant={'outlined'}
                            href={uploaded}
                            target={'_blank'}
                    >
                        Vedi {fileLabel}
                    </Button>
                    <CustomConfirmDialog label={'Elimina file'} confirmAction={deleteFile}
                                         icon={<DeleteForever/>}
                                         color={'primary'} variant={'standard'}/>
                </Stack>
                :
                <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF", "zip", "ZIP"]}
                    label={'Carica o trascina il file qui'}
                    hoverTitle={"Rilascia qui"}
                    children={
                        <Stack alignItems={'center'}
                               sx={{
                                   border: `2px dashed ${customTheme.palette.accent.main}`,
                                   borderRadius: '1rem',
                                   cursor: 'pointer',
                                   p: 2
                               }}>
                            {!file ?
                                <Typography textAlign={'center'}>
                                    Nessun file caricato,
                                    <br/>
                                    <u>clicca</u> o trascina il file qui
                                </Typography>
                                :
                                <Typography textAlign={'center'}>
                                    {`File caricato: ${file?.name}`}
                                    <br/>
                                    <u>clicca</u> o trascina il file qui per cambiare file
                                </Typography>
                            }
                        </Stack>
                    }
                />
            }
            {!uploaded &&
                <Box mt={1.5} display={"flex"} justifyContent={'center'}>
                    {loading ?
                        <CustomLoader/>
                        : <Button variant={'submit'}
                                  disabled={!file || !!uploaded}
                                  color={'secondary'}
                                  onClick={sendFile}
                        >
                            carica file
                        </Button>}
                </Box>}
        </Box>
    )
}
