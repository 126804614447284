import React, {useContext, useEffect, useState} from "react";
import {
    alpha,
    Box,
    Button, Chip,
    Fab,
    FormControlLabel,
    FormGroup,
    Grow,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CustomPageHeader from "../../components/CustomPageHeader";
import {Add, Save} from "@mui/icons-material";
import CustomSwitch from "../../components/CustomSwitch";
import {GlobalContext} from "../../state/global";
import SettingsService, {forms} from "../../services/settings.service";
import {setGlobalSettings} from "../../state/global/globalActions";
import useAxios from "axios-hooks";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomLoader from "../../components/CustomLoader";
import {orderBy, partition} from "lodash";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";
import CustomFormCard from "../../components/CustomFormCard";
import SetImage from "../../components/SetImage";
import axios from "axios";
import {SettingsDialogTypes} from "./SettingsDialog";
import CheckinFieldsToShow from "./CheckinFieldsToShow";
import HandleFormDialog from "./HandleFormDialog";
import {customTheme, paletteKampaay} from "../../theme/customTheme";
import {useParams} from "react-router-dom";
import {useAuthContext} from "../../auth/hooks";

const Settings = () => {
    const {event_id} = useParams()
    const {isAdmin} = useAuthContext()
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data, loading: loadingSettings, error: errorSettings}, saveSettings] = useAxios(
        {method: "POST"}, {manual: true}
    )

    const [settings, setSettings] = useState({
        eventName: globalState?.settings?.eventName || '',
        logoUrl: globalState?.settings?.logoUrl || null,
        eventCoverUrl: globalState?.settings?.eventCoverUrl || null,
        isWhitelisted: globalState?.settings?.isWhitelisted || false,
        whitelist: globalState?.settings?.whitelist?.toString()
            .replaceAll(',','\n') || [],
        checkin: false,
        checkInFields: globalState?.settings?.checkInFields || []
    })

    useEffect(() => {
        if (globalState.settings) {
            setSettings({
                eventName: globalState.settings.eventName || '',
                checkin: globalState.settings.checkin || false,
                logoUrl: globalState.settings.logoUrl || null,
                eventCoverUrl: globalState.settings.eventCoverUrl || null,
                isWhitelisted: globalState?.settings?.isWhitelisted || null,
                whitelist: globalState?.settings?.whitelist?.toString()
                    .replaceAll(',','\n') || [],
                checkInFields: globalState?.settings?.checkInFields || []
            })
        }
    }, [globalState.settings])

    const handleEventName = (event) => {
        setSettings({...settings, eventName: event.target.value});
    };

    const handleIsWhitelistedChange = (event) => {
        setSettings({...settings, isWhitelisted: event.target.checked});
    };

    function handleWhiteList(event) {
        setSettings({...settings, whitelist: event.target.value});
    }

    const handleCheckInChange = (event) => {
        setSettings({...settings, checkin: event.target.checked});
    };

    const handleSaveSettings = () => {
        /*console.log("whitelist:", settings.whitelist?.split('\n')
            .map((email) => email.trim().toLowerCase().replace(/[^a-zA-Z0-9._%+-@]/g, ""))
            .filter((email) => (!!email)))*/

        let emails = settings?.whitelist || []
        try {
            emails = partition(settings.whitelist?.split('\n'),
                function(o) {
                    return !!o && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(o);
                })
        } catch (e) {
            console.log("e:",e)
        }

        console.log("emails:",emails)

        saveSettings({
            url: SettingsService.settingsUrl(event_id),
            data: {
                ...settings,
                whitelist: emails[0] ?? []
            }
        })
            .then((res) => {
                dispatch(setGlobalSettings({
                    ...globalState.settings,
                    ...settings,
                    whitelist: emails[0] ?? []
                }))
            })
            .catch((err) => console.log("err:", err))
    }

    const handleUpdateImage = async (file, fieldId) => {
        //setSettings({...settings, logoUrl: file});
        const field = fieldId?.replace('Url', '')
        console.log("field:", field)

        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            axios({
                url: `${SettingsService.settingsUrl(event_id)}/${field}`,
                method: 'POST',
                data: formData,
            })
                .then((res) => {
                    //console.log("res:", res)
                    if (res)
                        dispatch(setGlobalSettings({
                            ...globalState.settings,
                            [fieldId]: res.data.url
                        }))
                })
                .catch((err) => console.log("handleUpdateImage --> err:", err))
        }
    }

    const [dialogState, setDialogState] = useState({
        open: false,
        data: null,
        type: null
    })

    const handleOpenDialog = () => {
        setDialogState({open: true, type: SettingsDialogTypes.NEW_DATA_STRUCTURES, data: null})
    }

    const handleCloseDialog = () => {

    }

    return (
        <Box pb={4}>
            {
                <div>
                    <CustomBackdrop open={loadingSettings} children={<CustomLoader/>}/>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomPageHeader title={"Impostazioni"} subtitle={'Descrizione impostazioni progetto'}/>
                        <Button variant={'outlined'} color={'accent'}
                                startIcon={<Save/>}
                                onClick={handleSaveSettings}>
                            SALVA
                        </Button>
                    </Box>
                    <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'} component={Paper}
                         variant={'outlined'}>
                        <Typography variant={'h6'}>Generali</Typography>
                        <Box display={'flex'} width={'100%'} alignItems={'center'} flexWrap={'wrap'}>
                            <Box flex={1} mr={2} maxWidth={'350px'}>
                                <Typography>Il tuo logo</Typography>
                                <SetImage caption={'Il tuo logo'} tooltip
                                          logoUrl={settings.logoUrl}
                                          handleUpdateImage={handleUpdateImage}
                                          fieldId={'logoUrl'}
                                />
                            </Box>
                            <Box flex={1} mr={2} maxWidth={'350px'}>
                                <Typography>Cover dell'evento</Typography>
                                <SetImage caption={'Cover dell\'evento'} tooltip
                                          logoUrl={settings.eventCoverUrl}
                                          handleUpdateImage={handleUpdateImage}
                                          fieldId={'eventCoverUrl'}
                                />
                            </Box>
                            <Box flex={1} ml={2} maxWidth={'400px'}>
                                <TextField size={'small'} variant={'standard'}
                                           sx={{mt: 1}}
                                           label={'Nome dell\'evento'}
                                           fullWidth
                                           value={settings.eventName}
                                           onChange={handleEventName}>
                                </TextField>
                            </Box>
                        </Box>
                    </Box>
                    {isAdmin &&
                        <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'}
                             component={Paper} variant={'outlined'}>
                            <Typography variant={'h6'}>I form dell'evento</Typography>
                            {
                                <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                                    {
                                        orderBy(Object.values(globalState?.dataStructures || []), 'position')
                                            .map((dataStructure, i) => {
                                                if (dataStructure.id !== forms.CHECKIN.id)
                                                    return <Box key={i} mt={1} mr={2}>
                                                        <CustomFormCard id={dataStructure.id}
                                                                        label={dataStructure.label}
                                                                        description={dataStructure.description?.slice(0,100) || ""}/>
                                                    </Box>
                                            })
                                    }
                                    {isAdmin &&
                                        <CustomTooltip title={'Aggiungi nuovo form'} children={
                                            <Stack justifyContent={'center'} alignItems={'center'} sx={{
                                                maxWidth: 260,
                                                minWidth: 260,
                                                height: 150,
                                                borderRadius: '1.1rem',
                                                background: alpha(customTheme.palette.accent.main, 0.1),
                                            }}
                                                   onClick={handleOpenDialog}
                                            >
                                                <IconButton color={'accent'} onClick={handleOpenDialog}>
                                                    <Add/>
                                                </IconButton>
                                            </Stack>
                                        }/>}
                                    {/*<SettingsDialog dialogState={dialogState} setDialogState={setDialogState}/>*/}
                                    <HandleFormDialog dialogState={dialogState} setDialogState={setDialogState}/>
                                </Box>
                            }
                        </Box>}
                    {isAdmin &&
                        <Stack p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'}
                             component={Paper} variant={'outlined'}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={"start"} spacing={1}>
                                <Typography variant={'h6'}>E-mail in Whitelist</Typography>
                                {globalState.settings?.isWhitelisted ?
                                    <Chip sx={{background: paletteKampaay.accent2, color: 'white', fontWeight: 'bold'}}
                                          label={globalState.settings?.whitelist?.length}/>
                                    : null}
                            </Stack>

                            <FormGroup>
                                <FormControlLabel
                                    control={<CustomSwitch
                                        checked={settings.isWhitelisted}
                                        onChange={handleIsWhitelistedChange}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />}
                                    label="L'evento prevede Whitelist"
                                    labelPlacement="start"/>
                                </FormGroup>
                                {settings?.isWhitelisted &&
                                    <Grow in={settings?.isWhitelisted}>
                                        {
                                            <TextField size={'small'} variant={'outlined'}
                                                       sx={{mt: 1, mx: 2}}
                                                       multiline
                                                       maxRows={10}
                                                       label={'Inserisci le e-mail che possono iscriversi all\'evento, UNA PER RIGA'}
                                                       fullWidth
                                                       value={settings.whitelist}
                                                //value={JSON.stringify(settings.whitelist?.toString(), null, '\n')}
                                                       onChange={handleWhiteList}>
                                            </TextField>
                                        }
                                    </Grow>}
                        </Stack>}
                    {isAdmin ?
                        <Stack p={3} my={1} component={Paper} variant={'outlined'}>
                            <Typography variant={'h6'}>Impostazioni di check in</Typography>
                            <Stack alignItems={'flex-start'} justifyContent={'center'}>
                                <Stack direction={'row'}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<CustomSwitch
                                                checked={settings.checkin}
                                                onChange={handleCheckInChange}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                            label="L'evento prevede check-in"
                                            labelPlacement="start"/>
                                    </FormGroup>
                                </Stack>
                                {settings?.checkin &&
                                    <Grow in={settings?.checkin}>
                                        <Box width={'60%'}>
                                            <CheckinFieldsToShow settings={settings} setSettings={setSettings}/>
                                        </Box>
                                    </Grow>}
                            </Stack>
                        </Stack>
                        : null}
                    <Fab onClick={handleSaveSettings}
                         color={'primary'}
                         size={"medium"}
                         variant={'action'}>
                        <Save/>
                    </Fab>
                </div>}
            {errorSettings && <Typography>Errore nel caricamento dei dati</Typography>}
        </Box>
    )
}

export default Settings