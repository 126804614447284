import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Button, Card, CircularProgress, Container, Snackbar, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/images/Logo-Kampaay-Orange-2.png'
import logoWinston from '../assets/images/logo_bicolor_1000px/Logo winston - centered - rosso bianco@1K.png'
import {Alert} from "@mui/lab";
import {customTheme} from "../theme/customTheme";
import {auth} from "../firebase/clientApp";
import {GlobalContext} from "../state/global";
import {setAuthState} from "../state/global/globalActions";
import jwt_decode from "jwt-decode";
import {useAuthContext} from "../auth/hooks";
import CustomLoader from "../components/CustomLoader";

const Landing = () => {
    const {data} = useParams()
    const {sso} = useAuthContext()

    document.body.style.backgroundColor = customTheme.palette.secondary.main;

    const sx = {
        "& input": {
            textAlign: "center",
            color: "white",
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
        },
    }

    const [message, setMessage] = useState('')


    const handleCloseError = () => setMessage("");

    return (
        <main>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}}>
                <Stack mt={2} alignItems={'center'}>
                    <img src={logoWinston} width={'20%'} style={{margin: '0rem'}}/>
                    <img src={logo} width={'40%'} style={{margin: '0rem'}}/>
                </Stack>
                <Card style={{height: '100%', textAlign: 'right'}} variant={'login'}>
                    <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                        <Typography variant={'h5'} color={'white'}>
                            Accedi tramite il tuo account Kampaay
                        </Typography>
                    </Stack>
                </Card>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error"
                           sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default Landing
