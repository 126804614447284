import {API_URL, baseEventUrl} from "../config";

const usersUrl = ({event_id, archived = false, all = false}) => {
    if(!!all && all === true)
        return `${baseEventUrl(event_id)}users?all=true`
    return !!archived ? `${baseEventUrl(event_id)}users${archived ? '?archived=true':''}`
        : `${baseEventUrl(event_id)}users/${event_id}?`
}

const userDataUrl = (event_id, id) => {
    return `${baseEventUrl(event_id)}users/${id||''}`
}

const archiveUserUrl = (event_id, id, archive) => {
    return archive ? `${userDataUrl(event_id, id)}/archive` : `${userDataUrl(event_id, id)}/unarchive`
}

const resendEmailToUserUrl = (event_id, id, emailType) => {
    return `${userDataUrl(event_id, id)}/resend-invitation${emailType ? ('/'+emailType) : ''}`
}

const UserService = {
    usersUrl,
    userDataUrl,
    archiveUserUrl,
    resendEmailToUserUrl
};

export default UserService;
