import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Alert, Card, Container, Snackbar, Stack, Typography} from "@mui/material";
import logo from '../assets/images/Logo-Kampaay-Orange-2.png'
import logoWinston from '../assets/images/logo_bicolor_1000px/Logo winston - centered - rosso bianco@1K.png'
import {customTheme} from "../theme/customTheme";
import {GlobalContext} from "../state/global";
import {setAuthState} from "../state/global/globalActions";
import {useAuthContext} from "../auth/hooks";
import CustomLoader from "../components/CustomLoader";

const SSO = () => {
    const {event_id, data} = useParams()
    const {sso} = useAuthContext()

    document.body.style.backgroundColor = customTheme.palette.secondary.main;

    const sx = {
        "& input": {
            textAlign: "center",
            color: "white"
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
        },
    }

    const [globalState, dispatch] = useContext(GlobalContext)

    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleLogin()
    }, [data]);

    const handleLogin = (e) => {
        setMessage("");
        setLoading(true);

        sso(data, event_id).then(
            (userData) => {
                dispatch(setAuthState({isAdmin: userData.role === 'ADMIN', role: userData.role}))
                navigate(`/${event_id}/`, {replace: true})
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 400) {
                    // redirect to redirectUrl
                    //window.location.href = error.response.data.redirectUrl;
                } else {
                    setMessage(resMessage);
                }
                setLoading(false);
                setMessage(resMessage);
            }
        )
    }

    const handleCloseError = () => setMessage("");

    return (
        <main>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}}>
                <Stack mt={2} alignItems={'center'}>
                    <img src={logoWinston} width={'20%'} style={{margin: '0rem'}}/>
                    <img src={logo} width={'40%'} style={{margin: '0rem'}}/>
                </Stack>
                <Card style={{height: '100%', textAlign: 'right'}} variant={'login'}>
                    <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                        {loading && <>
                            <CustomLoader/>
                            <Typography variant={'h5'} color={'white'}>
                                Caricamento in corso...
                            </Typography>
                        </>}
                    </Stack>
                </Card>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error"
                           sx={{width: '100%'}}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default SSO
