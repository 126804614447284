import PropTypes from 'prop-types';
import {useCallback, useContext, useEffect, useMemo, useReducer} from 'react';

import {AuthContext} from './auth-context';
import {isValidToken, setSession} from './utils';
import axios from "axios";
import {API_URL} from "../../../config";
import {GlobalContext} from "../../../state/global";
import {setAuthState} from "../../../state/global/globalActions";
import {useLocation, useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [globalState, globalDispatch] = useContext(GlobalContext)

  const currentLocation = useLocation().pathname

  const initialize = useCallback(async () => {
    try {

      axios.interceptors.request.use(
          async config => {
            const token = localStorage.getItem(STORAGE_KEY);
            if (token) {
              config.headers.authorization = `Bearer ${token}`;
            }
            return config;
          },
          error => {
            return Promise.reject(error)
          }
      );

      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const decoded = jwtDecode(accessToken);

        globalDispatch(setAuthState({isAdmin: decoded.role === 'ADMIN', role: decoded.role}))

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...decoded,
              accessToken,
            },
          },
        });
      } else {

        if (accessToken && !currentLocation.includes("/sso/")) {
          const decoded = jwtDecode(accessToken);
          setSession(null);
          window.location.href = decoded.redirectUrl;
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [currentLocation]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const sso = useCallback(async (data, event_id) => {
    const response = await axios.post(API_URL + "auth/sso", {
      data,
      event_id
    });

    const { token } = response.data;

    setSession(token);

    const userData = jwtDecode(token);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...userData,
          token,
        },
      },
    });

    return userData;
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    const token = localStorage.getItem(STORAGE_KEY);
    const decoded = jwtDecode(token);

    setSession(null);

    dispatch({
      type: 'LOGOUT',
    });

    window.location.href = decoded.redirectUrl;
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      isAdmin: state.user?.role === 'ADMIN',
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      sso,
      logout,
    }),
    [sso, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
