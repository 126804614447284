import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import Header from "./appbar/Header";
import {APP_BAR_HEIGHT} from "../theme/customTheme";
import {Box, Typography} from "@mui/material";
import CustomLoader from "./CustomLoader";
import {GlobalContext} from "../state/global";
import useAxios from "axios-hooks";
import SettingsService from "../services/settings.service";
import DataStructuresService from "../services/dataStructures.service";
import {setDataStructures, setGlobalSettings} from "../state/global/globalActions";
import {useAuthContext} from "../auth/hooks";

const ProtectedRoute = ({children}) => {
    console.log("ProtectedRoute >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    const {event_id} = useParams()
    const [globalState, dispatch] = useContext(GlobalContext)

    const navigate = useNavigate();

    const { loading, authenticated, method } = useAuthContext();

    const [checked, setChecked] = useState(false);

    const [{data: dataSettings, loading: loadingSettings, error: errorSettings}] = useAxios(
        SettingsService.settingsUrl(event_id), {method: "GET", useCache: false, manual: false}
    )

    const [{data: dataStructures, loading: loadingDataStructures, error: errorDataStructures}] = useAxios(
        DataStructuresService.dataStructuresUrlV2(event_id), {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if (dataSettings) {
            dispatch(setGlobalSettings(dataSettings.settings))
        }
    }, [dataSettings])

    useEffect(() => {
        if (dataStructures) {
            dispatch(setDataStructures(dataStructures.data))
        }
    }, [dataStructures])

    const check = useCallback(() => {
        if (!authenticated) {
            console.log("ProtectedRoute >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", "not authenticated")
            // const searchParams = new URLSearchParams({
            //     returnTo: window.location.pathname,
            // }).toString();

            navigate(`/`, {replace: true})
        } else {
            console.log("ProtectedRoute >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", "authenticated")
            setChecked(true);
        }
    }, [authenticated, method]);

    useEffect(() => {
        if(!loading) {
            check();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, authenticated]);

    if (!checked) {
        return null;
    }

    return <div className="App" /*style={{marginTop: APP_BAR_HEIGHT}}*/>
        {//globalState.settings && globalState.dataStructures &&
            // user ?
                <Header children={
                    <Box m={2} px={3}>
                        {children ?
                            children : <Outlet/>}
                    </Box>}/>
            // : <Typography>Finisci di fare il setup del progetto</Typography>
        }
        {(loading || loadingSettings || loadingDataStructures) && <CustomLoader/>}
        {(errorSettings || errorDataStructures) && <Typography>Ops... qualcosa è andato storto</Typography>}
    </div>
};

export default ProtectedRoute
