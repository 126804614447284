import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Menu} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DeleteForever, MoreVert} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import CustomTooltip from "../../components/CustomTooltip";
import {cloneDeep, remove} from "lodash";
import useAxios from "axios-hooks";
import DataStructuresService from "../../services/dataStructures.service";
import {useParams} from "react-router-dom";

const SectionMenu = ({dataStructureId, sectionId, formData, setFormData}) => {
    const {event_id} = useParams()
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, confirmAction: null});

    const [{data, loading: loading, error: error}, deleteSection] = useAxios(
        {method: "DELETE"}, {manual: true}
    )

    const handleOpenSectionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    function handleOpenConfirmDialog(confirmAction) {
        handleCloseUserMenu()
        setOpenConfirmDialog({open: !openConfirmDialog.open, confirmAction: confirmAction || null})
    }

    function handleDeleteSection() {
        // TODO delete section

        deleteSection(
            DataStructuresService.dataStructuresUrlV2(event_id, `${dataStructureId}/${sectionId}`))
            .then((res) => {
                //console.log("res:", res)
                const newSections = cloneDeep(formData.sections)
                remove(newSections, function (o) {
                    return o.id === sectionId
                })
                setFormData({
                    ...formData,
                    sections: newSections
                })
                setOpenConfirmDialog({open: false, confirmAction: null})
        })
            .catch((err) => {
                console.log("handleDeleteSection > err:",err)
            })
    }

    return (
        <Box sx={{zIndex: 1500}}>
            <IconButton onClick={handleOpenSectionMenu}>
                <MoreVert/>
            </IconButton>
            <Menu
                sx={{mt: '45px'}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={() => handleOpenConfirmDialog(handleDeleteSection)}>
                    <CustomTooltip title={'Elimina sezione'} children={
                        <><DeleteForever />
                            Elimina sezione</>
                    }/>
                </MenuItem>
                <Dialog open={openConfirmDialog.open} onClose={handleOpenConfirmDialog}>
                    <DialogContent>
                        <DialogContentText>
                            Sei sicuro?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogActions>
                            <Button onClick={handleOpenConfirmDialog}>Annulla</Button>
                            <Button onClick={openConfirmDialog.confirmAction ?
                                () => openConfirmDialog.confirmAction() : null} variant={'submit'}>
                                OK
                            </Button>
                        </DialogActions>
                    </DialogActions>
                </Dialog>
            </Menu>
        </Box>
    );
}

export default SectionMenu
