import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import {Outlet, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import './App.css';
import {customTheme} from "./theme/customTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Iscrizioni from "./pages/iscritti/Iscrizioni";
import Home from "./pages/Home";
import Settings from "./pages/settings/Settings";
import {GlobalContext} from "./state/global";
import {useContext, useEffect, useMemo, useReducer, useState} from "react";
import Logger from "./state/Logger";
import {globalReducer, initializeState} from "./state/global/globalReducer";
import UserDetails from "./pages/iscritti/UserDetails";
import CheckIn from "./pages/CheckIn";
import {AuthProvider} from "./auth/context/jwt";
import SSO from "./pages/SSO";
import Landing from "./pages/Landing";

const AppUI = () => {

    const {event_id} = useParams()

    document.body.style.backgroundColor = '#FFFFFF';
    const [globalState, dispatch] = useContext(GlobalContext)

    // useEffect(() => {
    //     auth.onAuthStateChanged(async (user) => {
    //         //console.log("user:", user)
    //         const token = await user?.getIdToken()
    //
    //         if (token) {
    //             /** L'utente deve contenere nei claim il campo "role" (ADMIN | AGENCY) */
    //             const role = jwt_decode(token)?.role
    //             console.log("role:", role)
    //             if(!!role) {
    //                 dispatch(setAuthState({isAdmin: role === 'ADMIN', role: role}))
    //                 setUser(user)
    //             } else {
    //                 navigate(`/RGfGHSMClYCvoLmwVNNb/login`, {replace: true})
    //                 //navigate(`/${event_id}/login`, {replace: true})
    //             }
    //         } else {
    //             navigate(`/RGfGHSMClYCvoLmwVNNb/login`, {replace: true})
    //             //navigate(`/${event_id}/login`, {replace: true})
    //         }
    //     })
    // },[])

    const readOnly = useMemo(() => {
        if(globalState?.authState?.role === 'CLIENT') {
            return true
        } else return false
    }, [globalState?.authState]);

    return (<Box /*mt={APP_BAR_HEIGHT/(3.5)}*/>
            <Routes>
                <Route path={'/'} element={<Landing />}/>
                <Route path={'/:event_id/sso/:data'} element={<SSO/>}/>
                {/*<Route path={'/:event_id/login'} element={<Login/>}/>*/}
                <Route element={<ProtectedRoute />}>
                    <Route path={'/:event_id'} element={<Home/>}/>
                    <Route path={'/:event_id/iscrizioni'} element={<Iscrizioni readOnly={readOnly}/>}/>
                    <Route path={'/:event_id/iscrizioni/user/:id'} element={<UserDetails readOnly={readOnly}/>}/>
                    {!readOnly && <Route path={'/:event_id/settings'} element={<Settings/>}/>}
                </Route>
            </Routes>
    </Box>)
}

const App = () => {
    console.log("Developed by\n" +
        "\n" +
        "______ _           _                \n" +
        "| ___ \\ |         | |               \n" +
        "| |_/ / | ___  ___| |__    ___ ___  \n" +
        "|  __/| |/ _ \\/ __| '_ \\  / __/ _ \\ \n" +
        "| |   | |  __/\\__ \\ | | || (_| (_) |\n" +
        "\\_|   |_|\\___||___/_| |_(_)___\\___/ \n" +
        "                                    \n" +
        "                                    \n")
    const {event_id} = useParams()
    const currentLocation = useLocation().pathname

    return (
        <GlobalContext.Provider
            value={useReducer(Logger(globalReducer), {}, () => initializeState({currentLocation: currentLocation}))}
            displayName="Global Context">
            <AuthProvider>
                <ThemeProvider theme={customTheme}>
                    <CssBaseline/>
                    <Routes>
                        <Route path={'/:event_id/checkin'} element={<CheckIn/>}/>
                        <Route path={'/*'} element={<AppUI/>}/>
                    </Routes>
                </ThemeProvider>
            </AuthProvider>
        </GlobalContext.Provider>
    );
}

export default App;
