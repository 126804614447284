import {
    alpha,
    Badge,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack
} from "@mui/material";
import CustomTooltip from "../../components/CustomTooltip";
import {Archive, BorderColor, FilterNone, LibraryAddCheck} from "@mui/icons-material";
import {customTheme} from "../../theme/customTheme";
import IconButton from "@mui/material/IconButton";
import {Alert} from "@mui/lab";
import React, {useState} from "react";
import axios from "axios";
import UserService from "../../services/user.service";
import MultipleUserUpateDialog from "./MultipleUserUpateDialog";
import {useParams} from "react-router-dom";

const MultipleSelection = ({
                               checkboxSelection,
                               setCheckboxSelection,
                               rowSelectionModel,
                               selectedForm,
                               setRowSelectionModel,
                               refetchUsers,
                               //openConfirmDialog,
                               //handleToggleConfirmDialog
                           }) => {

    const {event_id} = useParams()
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, confirmAction: null, responseMessage: ''});
    const handleToggleConfirmDialog = (confirmAction, responseMessage) => {
        //console.log("handleToggleConfirmDialog")
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            responseMessage: responseMessage || null,
            confirmAction: confirmAction || null
        })
    }

    //console.log("MultipleSelection > rowSelectionModel:", rowSelectionModel)

    function handleArchiveMultipleUsers() {
        axios.put(UserService.userDataUrl(event_id), {
            users: rowSelectionModel,
            fields: {
                archived: true,
                complete: false
            }
        }).then((res) => {
            handleToggleConfirmDialog()
            refetchUsers()
        })
            .catch((err) => {
                console.log("handleArchiveMultipleUsers > err:", err)
                setOpenConfirmDialog({
                    ...openConfirmDialog,
                    responseMessage: "Non è stato possibile archiviare gli utenti selezionati"
                })
            })
    }

    return (
        <Stack mr={1} direction={'row'} alignItems={'center'} spacing={1}
               sx={rowSelectionModel.length > 0 ? {
                   borderStyle: 'dashed',
                   borderRadius: '2rem',
                   borderColor: alpha(customTheme.palette.primary.main, 0.5),
                   px: 0.5
               } : {}}
        >
            <Badge badgeContent={rowSelectionModel?.length} color={'accent'} sx={{color: 'white'}}>
                <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                               children={
                                   <Chip label={'Seleziona'}
                                         sx={{px: 0.5, py: 1.8}}
                                         size={'small'}
                                         variant={checkboxSelection ? "filled" : 'outlined'}
                                         icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                         onClick={() => setCheckboxSelection(!checkboxSelection)}
                                   />
                               }/>
            </Badge>
            {rowSelectionModel.length > 0 &&
                <MultipleUserUpateDialog
                    selectedForm={selectedForm}
                    rowSelectionModel={rowSelectionModel}
                    openConfirmDialog={openConfirmDialog}
                    handleToggleConfirmDialog={handleToggleConfirmDialog}
                    refetchUsers={refetchUsers}
                />}
            {rowSelectionModel.length > 0 &&
                <Box //borderRadius={'2rem'}
                    //sx={{background: customTheme.palette.accent.main, color: 'white'}}
                >
                    <CustomTooltip title={'Archivia selezionati'} children={
                        <IconButton size={'small'}
                                    onClick={() => handleToggleConfirmDialog(handleArchiveMultipleUsers)}>
                            <Archive/>
                        </IconButton>
                    }/>
                </Box>}
            <Dialog open={openConfirmDialog.open} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    <DialogContentText>
                        {`Sei sicuro?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'dialog-button'} onClick={handleToggleConfirmDialog}>{'Annulla'}</Button>
                    <Button variant={'submit-accent'}
                            onClick={openConfirmDialog.confirmAction ? () => openConfirmDialog.confirmAction() : null}
                    >
                        {'CONFERMA'}
                    </Button>
                </DialogActions>
                {!!openConfirmDialog?.responseMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {openConfirmDialog?.responseMessage || ''}
                    </Alert>}
            </Dialog>
        </Stack>
    );
}

export default MultipleSelection