import {createTheme} from "@mui/material";
import {itIT} from '@mui/material/locale';

export const APP_BAR_HEIGHT = 60;

export const paletteKampaay = {
    background: "#ffffff",
    primary: "#f97b62",
    secondary: "#25427c",
    tertiary: "#007e8a",

    neutral: "#929399",

    error: "#ba1a1a",

    accent1: "#ff6420",
    accent2: "#2f6df1",
    accent3: "#04e3ff",
}

export const customTheme = createTheme({
    typography: {
        fontFamily: 'Figtree Regular',
        h1: {fontFamily: 'Figtree Medium'},
        h2: {fontFamily: 'Figtree Medium'},
        h3: {fontFamily: 'Figtree Medium'},
        h4: {fontFamily: 'Figtree Medium'},
        h5: {fontFamily: 'Figtree Medium'},
        h6: {fontFamily: 'Figtree Medium'},
        button: {fontFamily: 'Figtree Medium', fontSize: '16'},
    },

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    ":disabled": {
                        color: "lightGray",
                        textDecoration: "none",
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none"
                        }
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: 'white'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: APP_BAR_HEIGHT,
                    borderRadius: 0
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#f97b62",
                    '&:disabled': {
                        color: "rgba(249,123,98,0.25)",
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'secondary'},
                    style: {
                        color: "#30424b",
                        '&:disabled': {
                            color: "rgba(48,66,75,0.4)",
                        }
                    }
                },
            ]
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '1rem'
                }
            },
            variants: [
                {
                    props: {variant: 'menu'},
                    style: {
                        //color: 'white',
                        paddingTop: 4,
                        paddingBottom: 4,
                        borderRadius: '2rem'
                    }
                },
                {
                    props: {variant: 'menu-selected'},
                    style: {
                        //color: 'white',
                        backgroundColor: "rgba(250,123,97,0.2)",
                        paddingTop: 4,
                        paddingBottom: 4,
                        borderRadius: '2rem'
                    }
                },
                {
                    props: {variant: 'submit'},
                    style: {
                        color: 'white',
                        backgroundColor: "#f97b62",
                        paddingInline: '1.5rem',
                        borderRadius: '1rem',
                        boxShadow: '1px 1px 6px 0px rgba(48,66,75,0.45)',
                        '&:disabled': {
                            backgroundColor: "rgba(249,123,98,0.25)",
                            boxShadow: 'none',
                        }
                    }
                }
            ]
        },
        MuiFab: {
            variants: [{
                props: {variant: 'action'},
                style: {
                    top: 'auto',
                    right: '20px',
                    bottom: '40px',
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1010,
                    backgroundColor: '#f97b62'
                }
            }]
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: '3px',
                    backgroundColor: paletteKampaay.accent2,
                    display: 'flex',
                    justifyContent: 'center',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '1.1rem',
                    //boxShadow: '1px 1px 6px 0px rgb(140 144 146 / 15%)'
                },
            },
            variants: [
                {
                    props: {variant: 'login'},
                    style: {
                        width: '100%',
                        maxWidth: '37.5rem',
                        margin: '2rem auto',
                        height: '100%',
                        borderRadius: '2rem',
                        border: 0,
                        padding: '1rem 2rem 3rem 2rem',
                        backgroundColor: 'rgba(255,255,255,0.0)',
                    },
                },
            ]
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    background: 'rgba(48,66,75,0.95)',
                    color: 'white',
                    marginBottom: '1rem',
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    //border: '3px solid #30424b',
                    borderRadius: '1.1rem',
                    boxShadow: '0px 0px 18px 0px rgba(48,66,75,0.45)'
                },
            },
            variants: [
                {
                    props: {variant: 'checkin'},
                    style: {
                        width: '100%',
                        top: '140px',
                    },
                },
            ]
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    width: '100% !important'
                }
            }
        },
        MuiChip: {
            variants: [
                {
                    props: {variant: 'accent-outlined'},
                    style: {
                        border: `1px solid #f97b62`,
                        backgroundColor: `transparent !important`,
                        color: `#f97b62 !important`,
                        fontWeight: 'bold'
                    },
                },
            ]
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#30424b',
        },
        background: {
            default: paletteKampaay.background,
            paper: paletteKampaay.background
        },
        secondary: {
            main: '#252a33',
        },
        /*background: {
            default: '#252a33',
            paper: '#252a33',
        },*/
        accent: {
            main: '#f97b62',
            secondary: '#e6fc69',
        },
        text: {
            primary: '#263238',
        },
        error: {
            main: paletteKampaay.error
        },
        warning: {
            main: '#ffffb4'
        },
        success: {
            main: '#e4fde4'
        }
    },
    itIT,
});
