import {
    alpha,
    Badge, Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemAvatar, ListItemSecondaryAction,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AddCircle, Cancel, DragHandle, Save} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {setDataStructure} from "../../state/global/globalActions";
import {GlobalContext} from "../../state/global";
import DataStructuresService from "../../services/dataStructures.service";
import {camelCase, cloneDeep, filter, find, findIndex, forEach, includes, isEmpty, omit, remove} from "lodash";
import {fieldTypes} from "../../services/utils";
import MenuItem from "@mui/material/MenuItem";
import {customTheme} from "../../theme/customTheme";
import IconButton from "@mui/material/IconButton";
import Risposte from "./Risposte";
import CustomTooltip from "../../components/CustomTooltip";
import ColumnsService, {commonFields} from "../../services/columns.service";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {useParams} from "react-router-dom";
import {useAuthContext} from "../../auth/hooks";

export const SettingsDialogTypes = {
    NEW_DATA_STRUCTURES: {title: "Aggiungi nuovo form"},
    DATA_STRUCTURES_INFO: {title: "Modifica form:"},
    DATA_STRUCTURES_FIELDS: {
        title: "Aggiungi campi",
        subtitle: "Questi campi saranno utilizzati solo per il backoffice, e non saranno visibili ai partecipanti"
    },
    CHECKIN_PROPS: "CHECKIN_PROPS",
}

const SettingsDialog = ({dialogState, setDialogState}) => {
    const {event_id} = useParams()
    const {isAdmin} = useAuthContext()
    //console.log("dialogState:",dialogState)
    const [globalState, dispatch] = useContext(GlobalContext)

    const handleClose = () => {
        setDialogState({
            ...dialogState,
            open: false,
        })
    }

    const hasSections = !!dialogState.data?.sections

    /**
     * SettingsDialogTypes.NEW_DATA_STRUCTURES
     * &
     * SettingsDialogTypes.DATA_STRUCTURES_INFO
     */
    const [formData, setFormData] = useState({
        label: '',
        description: '',
        sections: []
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [event.target.id]: event.target.value});
    };

    useEffect(() => {
        setFormData({
            label: dialogState.data?.label || '',
            description: dialogState.data?.description || '',
            sections: /*dialogState.data?.sections ?
                dialogState.data?.sections.map((section) => (section.label)) : */[]
        })
    }, [dialogState])

    const handleSetSections = (opts) => {
        setFormData({
            ...formData,
            sections: opts
        })
    }

    /**
     * SettingsDialogTypes.DATA_STRUCTURES_FIELDS
     */
    const [fieldsData, setFieldsData] = useState({
        field: {
            id: '',
            label: '',
            type: fieldTypes.TEXT,
            defaultVisible: true,
            section: {},
            values: []
        },
        fieldsToAdd: [],
    })

    useEffect(() => {
        if (dialogState.data && dialogState.data.sections)
            setFieldsData({
                ...fieldsData,
                field: {
                    ...fieldsData.field,
                    section: dialogState.data.sections[0]
                }
            })
    }, [dialogState])

    const handleSetOptions = (opts) => {
        setFieldsData({
            ...fieldsData,
            field: {
                ...fieldsData.field,
                values: [...opts]
            }
        })
    }

    function isValid() {
        return !isEmpty(fieldsData.fieldsToAdd);
    }

    const onDragEnd = (event) => {
        //console.log("result:",event)
        // dropped outside the list
        if (!event.destination)
            return;
        let tempData = dialogState.data?.sections;
        let [source_data] = tempData.splice(event.source.index, 1);
        tempData.splice(event.destination.index, 0, source_data);
        //console.log("tempData:",tempData)
        setDialogState({
            ...dialogState,
            data: {
                ...dialogState.data,
                sections: tempData
            }
        })
    }

    return (
        <Dialog open={dialogState.open} onClose={handleClose} maxWidth={'xs'} fullWidth
                PaperProps={{variant: 'dialog'}} scroll={'paper'}>
            {/*<DialogTitle>{`${type.title} ${editMode ? dialogState?.data?.label : ''}`}</DialogTitle>*/}
            <DialogTitle>{dialogState.type?.title || ''}</DialogTitle>
            {(() => {
                switch (dialogState.type) {
                    case SettingsDialogTypes.NEW_DATA_STRUCTURES:
                    case SettingsDialogTypes.DATA_STRUCTURES_INFO:
                        const handleSubmit = () => {
                            // TODO: gestire le sections: concat con le section già presenti
                            const {data} = dialogState
                            const editMode = !!data?.id

                            let newDataStructure

                            let validSections = filter(formData.sections, function (o) {
                                return !!o
                            })
                            if (!editMode) {
                                validSections = validSections.map((section, i) => ({
                                    id: getId(section),
                                    label: section,
                                    position: i,
                                    fields: [],
                                    editable: true
                                }))
                                validSections.unshift({
                                    id: getId('mainSection'),
                                    label: "Dati Generali",
                                    position: 0,
                                    fields: commonFields,
                                    editable: true
                                })
                                newDataStructure = {
                                    ...formData,
                                    sections: validSections
                                }
                            } else {
                                const oldSections = dialogState.data?.sections || []
                                newDataStructure = {
                                    ...formData,
                                    sections: oldSections
                                        .concat(
                                            validSections.map((section, i) => ({
                                                id: getId(section),
                                                label: section,
                                                fields: [],
                                                editable: true
                                            }))
                                        ).map((section, i) => ({
                                            ...section,
                                            position: i
                                        }))
                                }

                            }

                            axios({
                                url: `${DataStructuresService.dataStructuresUrlV2(event_id, dialogState?.data?.id)}`,
                                method: dialogState.data ? 'PUT' : 'POST',
                                data: newDataStructure,
                            })
                                .then((res) => {
                                    if (res) {
                                        dispatch(setDataStructure({
                                            ...dialogState.data,
                                            ...res.data.data
                                        }))
                                    }
                                    handleClose()
                                })
                                .catch((err) => console.log("handleUpdateImage --> err:", err))
                        }

                        return <>
                            <DialogContent>
                                <Stack spacing={3}>
                                    <TextField variant={'standard'} sx={{marginTop: 1}}
                                               id={'label'}
                                               label={'Nome del form'}
                                               value={formData.label}
                                               onChange={handleChange}
                                    />
                                    <TextField variant={'outlined'} size={'small'} sx={{marginTop: 1}}
                                               id={'description'}
                                               label={'Descrizione'}
                                               multiline maxRows={3}
                                               value={formData.description}
                                               onChange={handleChange}
                                    />
                                    {hasSections && !isEmpty(dialogState.data?.sections) ?
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Typography gutterBottom variant={"body1"}>Le sezioni del form</Typography>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <List dense disablePadding
                                                          {...provided.droppableProps}
                                                          ref={provided.innerRef}
                                                    >
                                                        {(dialogState.data?.sections || []).map((section, index) => (
                                                            <Draggable key={section.id} draggableId={section.id}
                                                                       index={index}>
                                                                {(provided, snapshot) => (
                                                                        <ListItem
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            //{...provided.dragHandleProps}
                                                                            /*style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}*/
                                                                        >
                                                                            <Badge badgeContent={index + 1}
                                                                                   color={'primary'}
                                                                                   anchorOrigin={{
                                                                                       vertical: 'top',
                                                                                       horizontal: 'left',
                                                                                   }}
                                                                            >
                                                                                <Chip label={section.label}/>
                                                                            </Badge>
                                                                            {/*<ListItemText primary={section.label} secondary={section.id}/>*/}
                                                                            <CustomTooltip title={"Riordina"}>
                                                                                <ListItemSecondaryAction {...provided.dragHandleProps}>
                                                                                    <DragHandle fontSize={'small'}/>
                                                                                </ListItemSecondaryAction>
                                                                            </CustomTooltip>
                                                                        </ListItem>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </List>
                                                )}
                                            </Droppable>
                                        </DragDropContext> : null}
                                    {isAdmin &&
                                        <Risposte risp={formData.sections || []} handleSetRisposte={handleSetSections}
                                                  title={'Aggiungi sezione al form'} optionLabel={'Sezione'}
                                                  startIndex={hasSections ? dialogState.data?.sections.length : 0}
                                        />}
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Annulla</Button>
                                <Button disabled={!formData.label} onClick={handleSubmit} variant={'submit'}
                                        startIcon={<Save/>}>Salva</Button>
                            </DialogActions>
                        </>
                    case SettingsDialogTypes.DATA_STRUCTURES_FIELDS:

                    function getId(fieldName) {
                        return fieldName ? camelCase(fieldName.trim()
                            .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
                            .replaceAll(/\s+/g, "-")) : ""
                    }

                        const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const id = event.target.id || event.target.name
                            switch (id) {
                                case 'field.label':
                                    setFieldsData({
                                        ...fieldsData, field: {
                                            ...fieldsData.field,
                                            label: event.target.value,
                                            id: getId(event.target.value)
                                        }
                                    });
                                    break
                                case 'field.type':
                                    setFieldsData({
                                        ...fieldsData,
                                        field: {...fieldsData.field, type: event.target.value}
                                    });
                                    break
                                case 'field.section':
                                    setFieldsData({
                                        ...fieldsData,
                                        field: {...fieldsData.field, section: event.target.value}
                                    });
                                    break
                                default:
                                    return
                            }
                        };

                    function handleAddField() {
                        return setFieldsData({
                            fieldsToAdd: [...fieldsData.fieldsToAdd, fieldsData.field],
                            field: {
                                id: '',
                                label: '',
                                type: fieldTypes.TEXT,
                                section: dialogState.data.sections[0],
                                values: []
                            },
                        });
                    }

                    function handleDeleteField(data) {
                        remove(fieldsData.fieldsToAdd, ['id', data.id])
                        setFieldsData({
                            ...fieldsData,
                            fieldsToAdd: fieldsData.fieldsToAdd
                        })
                    }

                        const handleSubmitFields = () => {
                            const _res = cloneDeep(dialogState.data.sections)
                            forEach(fieldsData.fieldsToAdd, (field) => {
                                if (hasSections && !isEmpty(dialogState.data.sections)) {
                                    //console.log("_res:",_res)
                                    //console.log("field:",field)
                                    const sectionIndex = findIndex(_res, ['id', field.section.id])
                                    //delete field.section
                                    field = omit(field, 'section')
                                    //console.log("section:",sectionIndex)
                                    //console.log("_res[section]:",_res[sectionIndex])
                                    if (field.type === fieldTypes.SELECT || field.type === fieldTypes.RADIO_GROUP)
                                        _res[sectionIndex].fields.push({
                                            ...field,
                                            type: field.type.type,
                                            values: field.values
                                        })
                                    else _res[sectionIndex].fields.push({...field, type: field.type.type})
                                } else {
                                    if (field.type === fieldTypes.SELECT || field.type === fieldTypes.RADIO_GROUP)
                                        _res.push({
                                            ...field,
                                            type: field.type.type,
                                            values: field.values
                                        })
                                    else _res.push({...field, type: field.type.type})
                                }
                            })

                            const data = hasSections ? {sections: _res} : {fields: _res}
                            axios({
                                url: `${DataStructuresService.dataStructuresUrlV2(event_id, dialogState.data.id)}`,
                                method: 'PUT',
                                // TODO: migliorare unione dei campi
                                data: data,
                            })
                                .then((_res) => {
                                    if (_res)
                                        dispatch(setDataStructure({
                                            ...dialogState.data,
                                            ...data
                                        }))
                                    handleClose()
                                })
                                .catch((err) => console.log("handleSubmitFields --> err:", err))
                        }

                        return <>
                            <DialogContent>
                                <DialogContentText
                                    variant={'subtitle2'}>{dialogState.type?.subtitle}</DialogContentText>
                                <Stack spacing={2} alignItems={'start'} p={3} mt={2}
                                       sx={{
                                           border: `2px dashed ${alpha(customTheme.palette.primary.main, 0.35)}`,
                                           borderRadius: '1.5rem',
                                       }}>
                                    {hasSections && !isEmpty(dialogState.data.sections) &&
                                        <TextField variant={'outlined'} size={'small'}
                                                   select
                                                   fullWidth
                                                   id={'field.section'}
                                                   name={'field.section'}
                                                   label={'Sezione in cui inserire il campo'}
                                                   value={fieldsData.field.section}
                                                   onChange={handleChangeField}>
                                            {
                                                dialogState.data.sections.map((option) => (
                                                    <MenuItem key={option.section} value={option} children={
                                                        <Stack direction={'row'} spacing={1}>
                                                            <div>{option.label}</div>
                                                        </Stack>
                                                    }/>
                                                ))}
                                        </TextField>}
                                    <TextField variant={'standard'} size={'small'}
                                               id={'field.label'}
                                               fullWidth
                                               label={'Nome del campo'}
                                               error={!fieldsData.field.name
                                                   || includes(ColumnsService.getFieldsFromForm(dialogState.data)
                                                       .map((field) => (field.id)), fieldsData.field.id)}
                                               value={fieldsData.field.label}
                                               onChange={handleChangeField}
                                               helperText={includes(ColumnsService.getFieldsFromForm(dialogState.data)
                                                   .map((field) => (field.id)), fieldsData.field.id)
                                                   ? "Esiste già un campo con questo nome"
                                                   : `id: ${getId(fieldsData.field.label)}`}
                                    />
                                    <TextField variant={'outlined'} size={'small'}
                                               select
                                               fullWidth
                                               id={'field.type'}
                                               name={'field.type'}
                                               label={'Tipo del campo'}
                                               value={fieldsData.field.type}
                                               defaultValue={fieldTypes.TEXT}
                                               onChange={handleChangeField}>
                                        {
                                            filter(Object.values(fieldTypes), function (o) {
                                                return isAdmin || (o.admin === false)
                                            }).map((option) => (
                                                <MenuItem key={option.type} value={option} children={
                                                    <Stack direction={'row'} spacing={1}>
                                                        <div>{option.icon}</div>
                                                        <div>{option.label}</div>
                                                    </Stack>
                                                }/>
                                            ))}
                                    </TextField>
                                    {(fieldsData.field.type === fieldTypes.SELECT
                                            || fieldsData.field.type === fieldTypes.RADIO_GROUP)
                                        && <Risposte risp={fieldsData.field.values} handleSetRisposte={handleSetOptions}
                                                     title={'Aggiungi le opzioni possibili'} optionLabel={'Opzione'}
                                                     startIndex={0}
                                        />}
                                </Stack>
                                <List>
                                    <CustomTooltip title={fieldsData.field.label ?
                                        `Aggiungi campo '${fieldsData.field.label}'`
                                        : ""}
                                                   children={<span>
                                                <IconButton variant={'submit'}
                                                            disabled={!fieldsData.field.label || includes(ColumnsService.getFieldsFromForm(dialogState.data)
                                                                .map((field) => (field.id)), fieldsData.field.id)}
                                                            onClick={handleAddField}>
                                                    <AddCircle/>
                                                </IconButton>
                                    </span>}/>
                                    {
                                        fieldsData.fieldsToAdd.map((field, i) => (
                                            <Chip
                                                key={i}
                                                size={'small'}
                                                variant={'outlined'}
                                                color={'primary'}
                                                sx={{paddingLeft: 0.5, marginRight: 0.5, marginBottom: 0.5}}
                                                icon={field.type.icon}
                                                label={field.label}
                                                deleteIcon={<Cancel/>}
                                                onDelete={() => handleDeleteField(field)}
                                            />
                                        ))
                                    }
                                </List>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Annulla</Button>
                                <Badge badgeContent={fieldsData.fieldsToAdd.length} color="primary" anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}>
                                    <Button variant={'submit'} startIcon={<Save/>}
                                            disabled={!isValid()}
                                            onClick={handleSubmitFields}>
                                        Salva
                                    </Button>
                                </Badge>

                            </DialogActions>
                        </>
                    default:
                        return null
                }
            })()}
        </Dialog>
    )
}

export default SettingsDialog