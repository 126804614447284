import {
    AccessTime,
    AlternateEmail,
    CheckBox,
    CheckBoxOutlineBlank,
    DateRange, FiberManualRecord,
    HorizontalRule,
    Info,
    Link,
    MenuOpen,
    Notes, Numbers,
    QrCodeScanner,
    RadioButtonChecked,
    TextFields,
    Today,
    Tune,
    UploadFile
} from "@mui/icons-material";
import React from "react";
import {camelCase, forEach, replace} from "lodash";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    NUMBER: {icon: <Numbers color={'disabled'}/>, type: "number", label: "Numero", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: true},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: true},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: true},
    COLOR: {icon: <FiberManualRecord color={'disabled'}/>, type: "color", label: "Color", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
    FILE_UPLOAD: {icon: <UploadFile color={'disabled'}/>, type: "fileupload", label: "Caricamento File", admin: true, noDataGrid: false},
    DIVIDER: {icon: <HorizontalRule color={'disabled'}/>, type: "divider", label: "Divisore", admin: true, noDataGrid: true},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
}

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getId(fieldName) {
    return fieldName ? camelCase(fieldName.trim()
        .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
        .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
    return value ?
        replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
        : "";
}

export function testTime(value) {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
}

export const getColorFromCategoria = (braccialetto) => {
    switch (braccialetto) {
        case 'agente':
        case 'direttore':
        case 'presidente':
            return '#0095d9'
        case 'dipendente':
            return '#f59058'
        default:
            return null
    }
}